<script lang="ts">
    export let type: IconType;
    export let color: string;
    export let size = 48;
    export let viewBox = "0 0 48 48";
</script>

<svg class="no-new-word" width={size} height={size} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
    {#if type === 'chevron_right'}
        <path class="no-new-word" fill-rule="evenodd" clip-rule="evenodd" d="M23 25.2695C22.9991 25.0768 22.9261 24.8925 22.7971 24.7568L16.639 17.9982L22.7971 11.2395C22.8618 11.1705 22.9128 11.0885 22.9471 10.9982C22.9814 10.908 22.9982 10.8113 22.9966 10.714C22.9942 10.5694 22.9511 10.4289 22.873 10.3104C22.7948 10.1919 22.6851 10.1008 22.5579 10.0488C22.4307 9.9967 22.2918 9.98604 22.1588 10.0182C22.0259 10.0503 21.905 10.1237 21.8116 10.229L15.1928 17.4926C15.0691 17.6282 15 17.8095 15 17.9982C15 18.1868 15.0691 18.3681 15.1928 18.5037L21.8116 25.7673C21.9055 25.8746 22.0278 25.9495 22.1624 25.9819C22.297 26.0144 22.4378 26.003 22.5662 25.9492C22.6946 25.8954 22.8047 25.8017 22.8821 25.6804C22.9595 25.5592 23.0006 25.4159 23 25.2695Z" stroke-width="2" stroke={color} stroke-linecap="round" />
    {:else if type === 'chevron_sm_up'}
        <path class="no-new-word" d="M4.36589 10.15L4.36597 10.15C4.4987 10.1494 4.62742 10.0994 4.72369 10.0085L8.00092 7.02241L11.2785 10.0088C11.3274 10.0544 11.385 10.09 11.4476 10.1138C11.5107 10.1377 11.5779 10.1494 11.6454 10.1483L11.6455 10.1483C11.7458 10.1466 11.8439 10.1168 11.9274 10.0617C12.0109 10.0066 12.0766 9.92829 12.1144 9.83579C12.1524 9.74316 12.1602 9.64149 12.1367 9.5442C12.1133 9.44737 12.0603 9.36061 11.9858 9.2943L8.35479 5.98558C8.25851 5.89778 8.1315 5.85 8.00092 5.85C7.87034 5.85 7.74333 5.89778 7.64705 5.98558L4.01638 9.29398C3.94073 9.36056 3.88682 9.44815 3.86322 9.54605C3.83947 9.64458 3.84791 9.74756 3.88706 9.84105C3.92617 9.93441 3.99361 10.013 4.07908 10.0675L4.15978 9.94107L4.07908 10.0675C4.16446 10.122 4.2644 10.1504 4.36589 10.15Z" fill={color} stroke={color} stroke-width="0.3"/>
    {:else if type === 'chevron_sm_down'}
        <path class="no-new-word" d="M11.6341 5.85L11.634 5.85001C11.5013 5.85064 11.3726 5.90062 11.2763 5.99154L7.99908 8.97759L4.72151 5.99123C4.67262 5.94558 4.61504 5.91001 4.55235 5.88621C4.4893 5.86227 4.42208 5.8506 4.35458 5.8517L4.35446 5.8517C4.25425 5.85341 4.15613 5.88323 4.07264 5.93829C3.98906 5.99341 3.92341 6.07171 3.88556 6.16421C3.84765 6.25684 3.83977 6.35851 3.86327 6.4558C3.88666 6.55263 3.93972 6.63939 4.01418 6.7057L7.64521 10.0144C7.74149 10.1022 7.8685 10.15 7.99908 10.15C8.12966 10.15 8.25667 10.1022 8.35295 10.0144L11.9836 6.70602C12.0593 6.63944 12.1132 6.55185 12.1368 6.45395C12.1605 6.35542 12.1521 6.25244 12.1129 6.15895C12.0738 6.06559 12.0064 5.98703 11.9209 5.93249L11.8402 6.05893L11.9209 5.93249C11.8355 5.87799 11.7356 5.84957 11.6341 5.85Z" fill={color} stroke={color} stroke-width="0.3"/>
    {:else if type === 'pause'}
        <rect class="no-new-word" x="0" fill="none"/>
        <g class="no-new-word">
            <path class="no-new-word" d="M5 16V4h3v12H5zm7-12h3v12h-3V4z" fill={color}/>
        </g>
    {:else if type === 'play'}
        <g class="no-new-word" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g class="no-new-word" transform="translate(-427.000000, -3765.000000)" fill={color}>
                <g class="no-new-word" transform="translate(56.000000, 160.000000)">
                    <polygon class="no-new-word" points="371 3605 371 3613 378 3609"></polygon>
                </g>
            </g>
        </g>
    {:else if type === 'sun'}
        <g class="no-new-word">
            <path class="no-new-word" d="M 45 68 c -12.682 0 -23 -10.317 -23 -23 c 0 -12.682 10.318 -23 23 -23 c 12.683 0 23 10.318 23 23 C 68 57.683 57.683 68 45 68 z" fill={color} stroke-linecap="round" />
            <path class="no-new-word" d="M 38.652 17.61 c -0.292 0 -0.573 -0.127 -0.765 -0.356 c -0.239 -0.284 -0.301 -0.677 -0.161 -1.021 l 6.348 -15.61 C 44.227 0.247 44.593 0 45 0 s 0.773 0.247 0.926 0.623 l 6.349 15.61 c 0.14 0.344 0.077 0.737 -0.162 1.021 c -0.238 0.284 -0.616 0.414 -0.978 0.333 c -4.045 -0.881 -8.228 -0.881 -12.271 0 C 38.794 17.603 38.723 17.61 38.652 17.61 z" fill={color} stroke-linecap="round" />
            <path class="no-new-word" d="M 45 90 c -0.407 0 -0.773 -0.246 -0.926 -0.623 l -6.348 -15.61 c -0.14 -0.344 -0.078 -0.737 0.161 -1.021 c 0.239 -0.284 0.615 -0.412 0.978 -0.333 c 4.043 0.882 8.226 0.882 12.271 0 c 0.363 -0.08 0.74 0.05 0.978 0.333 c 0.239 0.283 0.302 0.677 0.162 1.021 l -6.349 15.61 C 45.773 89.754 45.407 90 45 90 z" fill={color} transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            <path class="no-new-word" d="M 16.61 52.349 c -0.127 0 -0.255 -0.024 -0.377 -0.073 l -15.61 -6.349 C 0.247 45.773 0 45.407 0 45 s 0.247 -0.773 0.624 -0.926 l 15.61 -6.348 c 0.343 -0.14 0.737 -0.078 1.021 0.161 c 0.284 0.239 0.412 0.616 0.333 0.978 c -0.441 2.021 -0.665 4.086 -0.665 6.135 c 0 2.049 0.224 4.113 0.665 6.136 c 0.079 0.362 -0.049 0.739 -0.333 0.978 C 17.071 52.269 16.842 52.349 16.61 52.349 z" fill={color} stroke-linecap="round" />
            <path class="no-new-word" d="M 73.39 52.349 c -0.231 0 -0.461 -0.08 -0.644 -0.235 c -0.284 -0.238 -0.412 -0.615 -0.333 -0.978 c 0.44 -2.022 0.664 -4.087 0.664 -6.136 c 0 -2.049 -0.224 -4.114 -0.664 -6.135 c -0.079 -0.362 0.049 -0.739 0.333 -0.978 c 0.283 -0.239 0.676 -0.301 1.021 -0.161 l 15.61 6.348 C 89.754 44.227 90 44.593 90 45 s -0.246 0.773 -0.623 0.926 l -15.61 6.349 C 73.645 52.324 73.517 52.349 73.39 52.349 z" fill={color} stroke-linecap="round" />
            <path class="no-new-word" d="M 20.437 30.415 c -0.028 0 -0.057 -0.001 -0.085 -0.004 c -0.37 -0.032 -0.692 -0.266 -0.836 -0.607 l -6.549 -15.527 c -0.158 -0.375 -0.073 -0.808 0.214 -1.096 c 0.288 -0.288 0.722 -0.371 1.096 -0.214 l 15.527 6.549 c 0.342 0.144 0.576 0.466 0.607 0.835 c 0.032 0.37 -0.144 0.727 -0.456 0.927 c -1.743 1.119 -3.36 2.42 -4.809 3.868 c -1.448 1.449 -2.75 3.066 -3.868 4.809 C 21.093 30.243 20.775 30.415 20.437 30.415 z" fill={color} stroke-linecap="round" />
            <path class="no-new-word" d="M 76.112 77.112 c -0.131 0 -0.263 -0.025 -0.389 -0.078 l -15.526 -6.549 c -0.342 -0.145 -0.576 -0.467 -0.607 -0.836 c -0.032 -0.37 0.144 -0.727 0.456 -0.928 c 1.745 -1.121 3.363 -2.423 4.808 -3.868 l 0 0 c 1.445 -1.444 2.747 -3.063 3.868 -4.808 c 0.201 -0.312 0.553 -0.489 0.928 -0.456 c 0.369 0.031 0.691 0.266 0.836 0.607 l 6.549 15.526 c 0.157 0.375 0.073 0.809 -0.215 1.096 C 76.628 77.011 76.372 77.112 76.112 77.112 z" fill={color} stroke-linecap="round" />
            <path class="no-new-word" d="M 69.563 30.414 c -0.339 0 -0.656 -0.171 -0.842 -0.459 c -1.121 -1.746 -2.423 -3.363 -3.868 -4.809 l 0 0 c -1.447 -1.447 -3.065 -2.749 -4.808 -3.868 c -0.313 -0.2 -0.488 -0.557 -0.456 -0.927 c 0.031 -0.37 0.266 -0.691 0.607 -0.835 l 15.526 -6.549 c 0.373 -0.158 0.808 -0.074 1.096 0.214 c 0.288 0.288 0.372 0.721 0.215 1.096 l -6.549 15.527 c -0.145 0.342 -0.467 0.576 -0.836 0.607 C 69.62 30.413 69.592 30.414 69.563 30.414 z" fill={color} stroke-linecap="round" />
            <path class="no-new-word" d="M 13.887 77.112 c -0.26 0 -0.516 -0.102 -0.707 -0.293 c -0.288 -0.288 -0.373 -0.721 -0.214 -1.096 l 6.549 -15.526 c 0.144 -0.342 0.466 -0.576 0.835 -0.607 c 0.37 -0.043 0.727 0.144 0.927 0.456 c 1.119 1.742 2.421 3.36 3.868 4.808 l 0 0 c 1.446 1.446 3.063 2.747 4.809 3.868 c 0.312 0.201 0.488 0.558 0.456 0.928 c -0.032 0.369 -0.266 0.691 -0.607 0.836 l -15.527 6.549 C 14.15 77.087 14.019 77.112 13.887 77.112 z" fill={color} stroke-linecap="round" />
        </g>
    {:else if type === 'moon'}
        <g class="no-new-word">
            <path class="no-new-word" d="M 87.823 60.7 c -0.463 -0.423 -1.142 -0.506 -1.695 -0.214 c -15.834 8.398 -35.266 2.812 -44.232 -12.718 c -8.966 -15.53 -4.09 -35.149 11.101 -44.665 c 0.531 -0.332 0.796 -0.963 0.661 -1.574 c -0.134 -0.612 -0.638 -1.074 -1.259 -1.153 c -9.843 -1.265 -19.59 0.692 -28.193 5.66 C 13.8 12.041 6.356 21.743 3.246 33.35 S 1.732 57.08 7.741 67.487 c 6.008 10.407 15.709 17.851 27.316 20.961 C 38.933 89.486 42.866 90 46.774 90 c 7.795 0 15.489 -2.044 22.42 -6.046 c 8.601 -4.966 15.171 -12.43 18.997 -21.586 C 88.433 61.79 88.285 61.123 87.823 60.7 z" fill={color} stroke-linecap="round" />
        </g>
    {:else if type === 'coffee'}
        <path d="M12.406 14.75c-0.094-2.094-0.219-3.219-1.469-4.594-1.594-1.781-2.188-3.5-0.875-6.156 0.344 1.781 0.469 3.375 1.719 4.344s2.281 3.594 0.625 6.406zM10.063 14.75c-0.063-1.125-0.125-1.688-0.813-2.469-0.844-0.938-1.188-1.844-0.469-3.281 0.188 0.969 0.219 1.813 0.906 2.313s1.281 1.938 0.375 3.438zM15.719 24.625h5.688c0.344 0 0.469 0.25 0.25 0.531 0 0-2.219 2.844-5.281 2.844h-10.969s-5.281-2.844-5.281-2.844c-0.219-0.281-0.125-0.531 0.219-0.531h5.625c-0.781-0.406-1.938-2.188-1.938-4.406v-4.688h13.688v0.375c0.438-0.375 0.969-0.563 1.531-0.563 0.781 0 2.25 0.813 2.25 2.219 0 2.031-1.344 2.781-2.125 3.313 0 0-1.469 1.156-2.5 2.5-0.344 0.594-0.75 1.063-1.156 1.25zM19.25 16.188c-0.5 0-1.125 0.219-1.531 1.219v2.594c0 0.344-0.031 0.75-0.094 1.094 0.688-0.688 1.5-1.156 1.5-1.156 0.5-0.344 1.5-1 1.5-2.281 0.031-0.906-0.813-1.469-1.375-1.469zM6.406 16.563h-0.875v1.281h0.875v-1.281zM6.406 18.594h-0.875v2.094s0.25 2.813 2.031 3.656c-1.094-1.281-1.156-2.75-1.156-3.656v-2.094z" fill={color}></path>
    {/if}
</svg>

<style>
    svg {
        margin: 0 auto;
    }
</style>